import * as React from 'react';
import styled from 'styled-components';

import PartnersList from './partners-list';
import { SmallerText, Subtitle } from '../../styles/typography';
import { Container } from '../../styles/layout';

const PartnersSection = styled.section`
	padding-top: 120px;
	padding-bottom: 136px;
`;

const Partners = () => {
	return (
		<PartnersSection>
			<Container>
				<Subtitle>Partnerji</Subtitle>
				<SmallerText marginBottom={45}>
					Verjamemo, da <strong>sodelovanje in tehnološka partnerstva</strong> spodbujajo razvoj na vseh
					ravneh.
				</SmallerText>
				<PartnersList />
			</Container>
		</PartnersSection>
	);
};

export default Partners;
