import { Link } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { Subtitle, TitleUnderlined } from '../../styles/typography';
import { ButtonWithGradient, Container, MobileHeroImageContainer } from '../../styles/layout';
import { ImageOffice2 } from '../../assets/images';

export interface Props {
	isHomePage?: boolean;
}

const ActionableBannerSection = styled.section<{ isHomePage?: boolean }>`
	text-align: center;
	padding-top: ${(props) => (props?.isHomePage ? 120 : 80)}px;
	padding-bottom: ${(props) => (props?.isHomePage ? 120 : 80)}px;
	background-color: ${(props) => (props?.isHomePage ? 'rgb(243, 246, 244)' : 'rgba(57, 171, 71, 0.05)')};

	img {
		width: 100%;
		height: auto;
	}
`;

const ActionableBannerSectionSubtitle = styled(Subtitle)`
	text-align: center;
	margin-bottom: 42px;

	${down('lg')} {
		br {
			display: none;
		}
	}
`;

const ActionableBannerButtonWithGradient = styled(ButtonWithGradient)`
	margin-top: 42px;
`;

const ActionableBanner = ({ isHomePage }: Props) => {
	return (
		<ActionableBannerSection isHomePage={isHomePage}>
			<Container>
				<ActionableBannerSectionSubtitle>
					<TitleUnderlined>Sodobna zasnova</TitleUnderlined> prostora
					<br /> in bivalne izkušnje
				</ActionableBannerSectionSubtitle>
			</Container>
			<MobileHeroImageContainer>
				<Link to="/prostor">
					<ImageOffice2 />
				</Link>
			</MobileHeroImageContainer>
			<Container>
				<ActionableBannerButtonWithGradient to="/prostor">
					Sprehod po Tehnološkem parku<span className="icon icon-arrow"></span>
				</ActionableBannerButtonWithGradient>
			</Container>
		</ActionableBannerSection>
	);
};

export default ActionableBanner;
