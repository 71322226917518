import * as React from 'react';
import styled from 'styled-components';

import { SmallerText, Subtitle } from '../../styles/typography';
import { Container } from '../../styles/layout';
import { ImagePatternLeft, ImagePatternRight } from '../../assets/images';
import {
	ImageDavorin,
	ImageGasper,
	ImageMasa,
	ImageMateja,
	ImageMuhamed,
	ImagePeter,
} from '../../assets/images/people';
import { down } from 'styled-breakpoints';

const PeopleSection = styled.section`
	position: relative;
	padding-top: 120px;
	padding-bottom: 136px;
	background-color: rgba(57, 171, 71, 0.05);

	&::before {
		content: url(${ImagePatternLeft});
		position: absolute;
		left: 0;
		bottom: 192px;

		${down('md')} {
			display: none;
		}
	}

	&::after {
		content: url(${ImagePatternRight});
		position: absolute;
		right: 0;
		top: 173px;

		${down('md')} {
			display: none;
		}
	}
`;

const PeopleSectionContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
`;

const PeopleNarowContainer = styled.div`
	padding-right: 150px;
	padding-left: 150px;

	${down('md')} {
		padding-right: 0;
		padding-left: 0;
	}
`;

const PeopleBox = styled.div`
	display: flex;
	justify-content: start;
	background: rgb(255, 255, 255);
	box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	padding: 16px;
	margin-bottom: 12px;

	&:last-child {
		margin-bottom: 0;
	}

	img {
		width: 56px;
		height: 56px;
		border-radius: 100%;
		margin-right: 22px;
	}

	div {
		display: flex;
		flex-direction: column;
		align-self: center;

		strong {
			font-size: 19px;
			line-height: 27px;
		}

		span {
			color: rgba(0, 0, 0, 0.6);
		}
	}
`;

const peopleBoxes = [
	{
		avatar: ImageDavorin,
		name: 'Davorin Gabrovec',
		description:
			'Podjetnik, mentor, ustanovitelj podjetij Intera in Databox, soustanovitelj Tehnološkega Parka Ptuj',
	},
	{
		avatar: ImagePeter,
		name: 'Peter Ladič',
		description: 'Direktor in solastnik podjetja Intera ter soustanovitelj Tehnološkega parka Ptuj',
	},
	{
		avatar: ImageMuhamed,
		name: 'izr. prof. dr. Muhamed Turkanović',
		description: 'Vodja Blockchain Lab:UM + DIH UM + EDIH DIGI-SI, izredni profesor, raziskovalec in mentor',
	},
	{
		avatar: ImageMateja,
		name: 'dr. Mateja Verlič Brunčič',
		description: 'Strokovnjakinja za razvoj programske opreme in mentorica',
	},
	{
		avatar: ImageGasper,
		name: 'Gašper Vidovič',
		description: 'Produktni vodja na Databoxu, strokovnjak za design in uporabniško izkušnjo',
	},
	{
		avatar: ImageMasa,
		name: 'Maša Sajko, mag. kom.',
		description: 'Strokovnjakinja za tržno komuniciranje',
	},
];

const People = () => {
	return (
		<PeopleSection>
			<Container>
				<PeopleNarowContainer>
					<Subtitle>Strokovni svet</Subtitle>
					<SmallerText marginBottom={40}>
						Poslanstvo in odgovornost strokovnega sveta Tehnološkega parka sta vezana na sodelovanja in
						povezovanja z zunanjimi partnerji, evalvacijo strokovnih vprašanj in idej, razvoj vsebin ter
						mentorstva.
					</SmallerText>
					<PeopleSectionContainer>
						{peopleBoxes.map((box, index) => {
							return (
								<PeopleBox key={index}>
									<img src={box.avatar} alt={box.name} />
									<div>
										<strong>{box.name}</strong>
										<span>{box.description}</span>
									</div>
								</PeopleBox>
							);
						})}
					</PeopleSectionContainer>
				</PeopleNarowContainer>
			</Container>
		</PeopleSection>
	);
};

export default People;
