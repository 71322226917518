import * as React from 'react';
import styled from 'styled-components';
import { between, down } from 'styled-breakpoints';

import { SmallerText, Subtitle, Text } from '../../styles/typography';
import { Container } from '../../styles/layout';

const CommonValuesSection = styled.section`
	padding-top: 96px;
	padding-bottom: 116px;
	background-color: rgba(57, 171, 71, 0.05);
`;

const CommonValuesSectionContainer = styled.div`
	display: flex;

	${down('md')} {
		flex-wrap: wrap;
	}
`;

const CommonValuesSectionBox = styled.div`
	height: 345px;
	box-sizing: border-box;
	background-color: rgb(255, 255, 255);
	border-radius: 12px;
	padding: 48px 40px;
	margin-right: 20px;
	text-align: center;

	&:last-child {
		margin-right: 0;
	}

	.icon {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 120px;
		height: 120px;
		border-radius: 100%;
		background: linear-gradient(95.58deg, rgba(26, 159, 70, 0.1) -2.13%, rgba(97, 186, 71, 0.1) 102.87%);
		color: rgb(0, 148, 68);
		font-size: 55px;
	}

	${between('md', 'lg')} {
		padding: 24px 20px;
	}

	${down('md')} {
		margin-right: 0;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const CommonValuesSectionBoxText = styled(Text)`
	margin-top: 30px;
	font-size: 21px;
`;

const commonValuesBoxes = [
	{
		icon: 'knowledge',
		title: 'Znanje',
		text: 'Povezovanje znanj med globalnimi tehnološkimi podjetji in posamezniki.',
	},
	{
		icon: 'growth',
		title: 'Razvoj',
		text: 'Spodbujanje rasti in razvoja posameznika na vseh področjih življenja.',
	},
	{
		icon: 'goal',
		title: 'Uspeh',
		text: 'Globalna konkurenčnost z ohranjanjem razvoja v lokalnem okolju.',
	},
];

const CommonValues = () => {
	return (
		<CommonValuesSection>
			<Container>
				<Subtitle marginBottom={52}>Skupne vrednote</Subtitle>
				<CommonValuesSectionContainer>
					{commonValuesBoxes.map((box, index) => {
						return (
							<CommonValuesSectionBox key={index}>
								<div className={`icon icon-${box.icon}`}></div>
								<div>
									<CommonValuesSectionBoxText>
										<strong>{box.title}</strong>
									</CommonValuesSectionBoxText>
									<SmallerText>{box.text}</SmallerText>
								</div>
							</CommonValuesSectionBox>
						);
					})}
				</CommonValuesSectionContainer>
			</Container>
		</CommonValuesSection>
	);
};

export default CommonValues;
