import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../../styles/typography';
import { Container } from '../../styles/layout';

export interface Props {
	text: string | ReactNode;
	author: { avatar: string; name: string; title: string };
	marginBottom?: number;
}

const QuoteSection = styled.section<{ marginBottom?: number }>`
	margin-top: 80px;
	margin-bottom: ${(props) => `${props?.marginBottom}px`};
`;

const QuoteSectionContainer = styled.div`
	padding: 80px 41px;
	background-color: rgba(57, 171, 71, 0.05);
`;

const QuoteSectionText = styled(Text)`
	position: relative;
	margin-top: 0;

	.icon {
		position: absolute;
		top: -20px;
		left: 0;
		color: rgba(117, 192, 71, 0.1);
		font-size: 86px;
	}
`;

const QuoteSectionAuthor = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 56px;

	img {
		width: 68px;
		height: 68px;
		border-radius: 100%;
		margin-right: 20px;
	}

	div {
		display: flex;
		flex-direction: column;
		align-self: center;
	}
`;

const Quote = ({ text, author, marginBottom }: Props) => {
	return (
		<QuoteSection marginBottom={marginBottom}>
			<Container>
				<QuoteSectionContainer>
					<QuoteSectionText>
						<span className="icon icon-quote"></span>
						{text}
					</QuoteSectionText>
					<QuoteSectionAuthor>
						<img src={author.avatar} alt={author.name} />
						<div>
							<strong>{author.name}</strong>
							<span>{author.title}</span>
						</div>
					</QuoteSectionAuthor>
				</QuoteSectionContainer>
			</Container>
		</QuoteSection>
	);
};

export default Quote;
