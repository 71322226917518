import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

import { SmallerText, Subtitle } from '../../styles/typography';
import { ButtonHover, Container } from '../../styles/layout';
import { ImageLogoIntera, ImageLogoDatabox } from '../../assets/images/logos';
import { changeColorOpacity } from '../../utils/styles';

export interface Props {
	title: string;
	text: ReactNode;
}

const WorkspaceCompaniesSection = styled.section`
	padding-top: 120px;
	padding-bottom: 120px;

	h2 {
		position: relative;
		z-index: 2;
	}
`;

const WorkspaceCompaniesSectionContainer = styled.div`
	display: flex;

	${down('lg')} {
		flex-wrap: wrap;
	}
`;

const WorkspaceCompaniesSectionBox = styled.div<{ backgroundColor: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 244px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 8px;
	padding: 40px;
	margin-right: 20px;
	text-align: center;

	&:last-child {
		margin-right: 0;
	}

	${down('lg')} {
		margin-right: 0;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const ButtonAnimationWrapper = styled.div`
	height: 0;
	opacity: 0;
	transition: height 0.5s, opacity 0.5s;

	${WorkspaceCompaniesSectionBox}:hover & {
		height: 40px;
		opacity: 1;
	}

	${down('lg')} {
		height: 40px;
		opacity: 1;
	}
`;

const Button = styled(ButtonHover)<{ backgroundColor: string }>`
	font-size: 16px;
	line-height: 24px;
	padding: 8px 24px;
	font-weight: 400;
	color: rgb(255, 255, 255);
	background-color: ${(props) => props.backgroundColor};
	border-radius: 6px;
`;

const companies = [
	{
		logo: ImageLogoIntera,
		logoHeight: 28,
		logoAlt: 'Intera',
		description: (
			<>
				Največji ponudnik CRM rešitev
				<br />v Sloveniji in regiji.
			</>
		),
		buttonText: 'O podjetju',
		buttonLink: 'https://www.intrix.si/o-nas',
		backgroundColor: 'rgb(91, 171, 218)',
	},
	{
		logo: ImageLogoDatabox,
		logoHeight: 35,
		logoAlt: 'Databox',
		description: (
			<>
				Globalno podjetje za razvoj rešitev
				<br />s področja poslovne inteligence.
			</>
		),
		buttonText: 'Kultura in ekipa',
		buttonLink: 'https://databox.com/about',
		backgroundColor: 'rgb(37, 120, 189)',
	},
];

const WorkspaceCompanies = ({ title, text }: Props) => {
	return (
		<WorkspaceCompaniesSection>
			<Container>
				<Subtitle>{title}</Subtitle>
				<SmallerText marginBottom={52}>{text}</SmallerText>
				<WorkspaceCompaniesSectionContainer>
					{companies.map((company, index) => {
						return (
							<WorkspaceCompaniesSectionBox
								key={index}
								backgroundColor={changeColorOpacity(company.backgroundColor, 10)}
							>
								<img
									src={company.logo}
									height={company.logoHeight}
									width="auto"
									alt={company.logoAlt}
								/>
								<SmallerText marginTop={24} marginBottom={24}>
									{company.description}
								</SmallerText>
								<ButtonAnimationWrapper>
									<Button
										href={company.buttonLink}
										target="_blank"
										backgroundColor={company.backgroundColor}
									>
										{company.buttonText}
									</Button>
								</ButtonAnimationWrapper>
							</WorkspaceCompaniesSectionBox>
						);
					})}
				</WorkspaceCompaniesSectionContainer>
			</Container>
		</WorkspaceCompaniesSection>
	);
};

export default WorkspaceCompanies;
