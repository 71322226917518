const changeColorOpacity = (color: string, opacityPercentage: number): string => {
	let formattedColor = color.toLowerCase();

	if (isNaN(opacityPercentage)) {
		return color;
	}

	const opacity = String(opacityPercentage / 100);

	if (formattedColor.includes('rgba')) {
		const splittedColorByLastComma = color.split(','),
			colorOpacityPart = splittedColorByLastComma[3].replace(new RegExp('[0-1]?.[0-9]'), opacity);

		splittedColorByLastComma.splice(splittedColorByLastComma.length - 1, 1).push(colorOpacityPart);

		return splittedColorByLastComma.join(',');
	}

	if (formattedColor.includes('rgb')) {
		formattedColor = formattedColor.replace('rgb', 'rgba').replace(')', `,${opacity})`);
	}

	return formattedColor;
};

export { changeColorOpacity };
