import * as React from 'react';
import { HeadProps } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Quote from '../components/sections/quote';
import ActionableBanner from '../components/sections/actionable-banner';
import WorkspaceCompanies from '../components/sections/workspace-companies';
import CommonValues from '../components/sections/common-values';
import Partners from '../components/sections/partners';
import People from '../components/sections/people';
import { ReactCompareSlider } from 'react-compare-slider';
import { Text, Title, Subtitle, TitleHighlighted } from '../styles/typography';
import { Container, PatternImage } from '../styles/layout';
import { ImageBuildingNew, ImageBuildingOld } from '../assets/images';
import { ImageDavorin, ImageNuska, ImagePeter } from '../assets/images/people';
import { ImageSocialAbout } from '../assets/images/social';

export interface Props {
	location: Location;
}

const SectionWithPattern = styled.section`
	position: relative;
	margin-top: 80px;
`;

const HandleLine = styled.div`
	position: relative;
	background-color: rgb(255, 255, 255);
	width: 3px;
	height: 100%;
`;

const HandleCircle = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 62px;
	height: 62px;
	border-radius: 100%;
	background: linear-gradient(180deg, rgb(31, 161, 71) 0%, rgb(92, 185, 72) 100%);
	border: 4px solid rgb(255, 255, 255);
	display: inline-flex;
	column-gap: 12px;
	justify-content: center;
	align-items: center;
	color: rgb(255, 255, 255);
	font-size: 25px;
`;

const ReactCompareSliderRounded = styled(ReactCompareSlider)`
	border-radius: 24px;
`;

const ReactCompareSliderHandle = () => {
	return (
		<HandleLine>
			<HandleCircle>
				<span className="icon icon-chevron-arrow-left"></span>
				<span className="icon icon-chevron-arrow-right"></span>
			</HandleCircle>
		</HandleLine>
	);
};

export const AboutPage = ({ location }: Props) => {
	return (
		<Layout location={location}>
			<Container>
				<Title>
					Od ambiciozne vizije do novega
					<br />
					<TitleHighlighted>Tehnološkega parka</TitleHighlighted>
				</Title>
			</Container>

			<SectionWithPattern>
				<PatternImage />
				<Container>
					<ReactCompareSliderRounded
						itemOne={<ImageBuildingOld />}
						itemTwo={<ImageBuildingNew />}
						handle={<ReactCompareSliderHandle />}
						position={40}
					/>
				</Container>
			</SectionWithPattern>

			<Container>
				<Text>
					Zgodba Tehnološkega parka se je pravzaprav začela že leta 2011, ko je ekipa motiviranih mladih
					podjetnikov s Ptuja prvič obiskala Silicijevo dolino v ZDA, Kaliforniji in se prvič sprehodila skozi
					Googlov tehnološki park in obiskali Applov razvojni center v Cupertinu. Takrat so prvič začutili{' '}
					<strong>željo, da nekaj podobnega ustvarijo tudi doma</strong>.
				</Text>
			</Container>

			<Container>
				<Text>
					Po nekaj več kot 10. letih nam je uspelo vizijo realizirati s{' '}
					<strong>prvim Tehnološkim parkom na Ptuju</strong>. Odločitev za izbor lokacije ni bila težka. Nekoč
					slikovita zgodovinska stavba, nekdanja stavba vojašnice pod ptujskih gradom, je zadnja tri
					desetletja propadala in leta 2019 se je ponudila priložnost, da svojo vizijo uresničimo. Markantnost
					zgradbe in njena lokacija sta bili med ključnimi dejavnikov pri izbiri.{' '}
					<strong>Ustvarjati sredi mesta na prostoru obdanim z zelenim parkom</strong> na eni in s pogledom na
					Ptujskim gradom na drugi strani, pomembno prispeva tudi h kvaliteti bivanja v prostoru.
				</Text>
			</Container>

			<Container>
				<Subtitle marginTop={112} marginBottom={24}>
					Znanje, izkušnje in
					<br />
					<TitleHighlighted>spodbudno delovno okolje </TitleHighlighted>
					<br />
					na enem mestu
				</Subtitle>
				<Text>
					Že v fazi načrtovanja Tehnološkega parka smo razmišljali predvsem o tem, kako nuditi spodbudno
					delovno okolje. Želimo, da Tehnološki park na Ptuju postane stičišče tehnoloških znanj, ki jih
					današnje obdobje potrebuje, hkrati pa ustvarjati privlačno okolje za domače strokovnjake, ki bodo
					tukaj želeli ustvarjati in razvijati svojo karierno pot.
				</Text>
			</Container>

			<Container>
				<Text>
					Del vsebin v okviru Tehnološkega parka je namenjen tudi širši publiki, ki se lahko pridružijo
					različnim tematskim delavnicam in predavanjem v okviru Kreativnice.
				</Text>
			</Container>

			<Quote
				text={
					<>
						Naša želja je bila ustvariti okolje, ki bo ustvarjalno in vzpodbudno za razvoj, ter hkrati
						ponujalo vsebino, ki bo posameznikom dajala možnost{' '}
						<strong>za rast na vseh področjih življenja</strong>. Najsi bo to pri razvoju specifičnih znanj
						in veščin, osebnostni rasti, spodbudah za zdrav način življenja, pa tudi z grajenjem skupnosti,
						ki svoje znanje in izkušnje deli v širše lokalno okolje.
					</>
				}
				author={{
					avatar: ImageDavorin,
					name: 'Davorin Gabrovec',
					title: 'Podjetnik, mentor in investitor',
				}}
			/>

			<Quote
				text="Ljudje so ključni za razvoj vsakega podjetja in zato smo tudi na Ptuju želeli ustvariti okolje, kjer bomo imeli odlične pogoje za ustvarjanje, obenem pa bodo imeli vsi sodelavci veliko možnosti za lasten razvoj, sprostitev in deljenje znanja."
				author={{
					avatar: ImagePeter,
					name: 'Peter Ladič',
					title: 'Direktor Intere',
				}}
			/>

			<Quote
				text="Dolgo smo pričakovali obnovo te izjemne zgradbe, kulturnega spomenika. Verjamem, da bodo zdaj tudi drugi podjetniki spoznali priložnosti, ki jih ponuja naše mesto. Davorin, Peter in ekipa pa so naredili izjemno zgodbo in ustvarili delovno okolje, v katerem se čutijo toplina, igrivost in domačnost."
				author={{
					avatar: ImageNuska,
					name: 'Nuška Gajšek',
					title: 'Županja MO Ptuj',
				}}
				marginBottom={80}
			/>

			<ActionableBanner />

			<WorkspaceCompanies
				title="V Tehnološkem parku delujejo"
				text={
					<>
						Danes v Tehnološkem parku delujeta Intera, prejemnik Dnevnikove Zlate niti za naj zaposlovalca,
						ter razvojna ekipa ameriškega Databox-a, obe družinam prijazni podjetji.
					</>
				}
			/>

			<CommonValues />

			<Partners />

			<People />
		</Layout>
	);
};

export const Head = (props: HeadProps) => {
	return (
		<Seo
			title={'O tehnološkem parku'}
			description={
				'Stičišče tehnoloških znanj, izkušenj in spodbudega delovnega okolja, kjer skupaj ustvarja 100+ strokovnjakov, inženirjev in doktorjev znanosti.'
			}
			image={ImageSocialAbout}
			locationPathName={props.location.pathname}
		/>
	);
};

export default AboutPage;
